<template>
  <div class="secondary white--text">
    <v-container @click="clicked" class="px-0 px-md-3 py-0">
      <!-- SOCIAL -->
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          class="align-center d-flex flex-column flex-sm-row justify-center py-6"
        >
          <span class="font-weight-bold text-center text-md-left mr-md-4">
            {{ $t("footer.social.followUs") }}
          </span>
          <span class="d-flex justify-center align-center mt-4 mt-sm-0">
            <a href="#">
              <img
                :src="require('@/assets/footer/facebook.svg')"
                class="mx-4 mx-sm-2"
                height="30"
                width="30"
                contain
              />
            </a>
            <!-- Logo Instagram -->
            <a href="#">
              <img
                :src="require('@/assets/footer/instagram.svg')"
                class="mx-4 mx-sm-2"
                height="30"
                width="30"
                contain
              />
            </a>
            <!-- Logo Youtube -->
            <a href="#">
              <img
                :src="require('@/assets/footer/youtube.svg')"
                class="mx-4 mx-sm-2"
                height="30"
                width="30"
                contain
              />
            </a>
          </span>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="secondary darken-1 align-center d-flex flex-column flex-sm-row justify-center px-4 py-6"
        >
          <span class="font-weight-bold text-center text-md-left mr-md-4">
            {{ $t("footer.social.subscribe") }}
          </span>
          <v-btn
            color="primary"
            elevation="0"
            class="ml-0 ml-md-4 mt-5 mt-sm-0"
            @click="openModal"
          >
            {{ $t("footer.social.subscribeBtn") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FormNewsletter from "@/components/wordpress/FormNewsletter";
import clickHandler from "~/mixins/clickHandler";
import login from "~/mixins/login";

export default {
  name: "NewsletterHorizontal",
  props: ["links"],
  mixins: [clickHandler, login],
  data() {
    return { modalOpen: false, dialogInstance: null };
  },
  methods: {
    async openModal() {
      this.dialogInstance = await this.$dialog.show(FormNewsletter, {
        width: "1100px"
      });
      const result = await this.dialogInstance.wait();
      if (result) {
        this.$emit("submit", true);
      }
    }
  }
};
</script>
